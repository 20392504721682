.App {
  height: 100%;
  width: 100%;
  background-color: #f0f0f7;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  height: 100%;
  /*transition: all 0.15s;*/
}

.expanded-menu {
  margin-left: 200px;
}

.closed-menu {
  margin-left: 70px;
}

.App-link {
  color: #09d3ac;
}
