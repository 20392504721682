/*
 Tooltip 
 */
.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 0.8rem;
}

/* 
 Menu and MenuItem 
 */
.MuiListItem-button .icon {
  margin-right: 1rem;
}
