.header {
  background-color: white;
  box-shadow: 0px 2px 3px #0000000d;
  padding: 0 2rem;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 1;
}

.select {
  color: #4d4f5c;
  width: 339px;
  height: 32px;
  margin-left: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #0000000d;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
