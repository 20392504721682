.navbar {
  display: flex;
  flex-direction: column;
  background-color: #43425d;
  color: white;
  height: 100%;
  text-align: left;
  position: absolute;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 0;
  /*transition: transform 0.3s ease-in-out;*/
  /*transition: all .15s;*/
  justify-content: space-between;
  align-content: space-between;
}

.icon {
  font-size: 1.5em;
  vertical-align: middle;
}

.sidenav---navicon---3gCRo {
  color: white;
}

#root .sidenav---sidenav---_2tBP {
  background-color: #43425d;
  min-width: 70px;
}

#root .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 200px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T {
  color: white;
  padding: 0 14px 0 20px;
  line-height: 28px;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: white;
  opacity: 1;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: white;
}

.App-link {
  color: #09d3ac;
}
