html {
  font-size: 16px;
}

body {
  font-size: 1rem;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  background-color: #f0f0f7;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.card {
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.34);
  background-color: #fff;
  padding: 1rem 2rem;
}
